import axios from "axios";
import store from "../store"

const API_URL = process.env.VUE_APP_ADMIN_LANGUAGE_URL;
const API_WORD = "/core";

axios.defaults.headers.common["Authorization"] = "Bearer " + store.getters.getToken;
class LocaleServcie {
    sendMissing(local, key, service) {
        let payload = {
            fullname : key
        }
        axios.post(`${API_URL}${API_WORD}/${service}/${local}`, payload);
    }
    getResponse(language, service) {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open( "GET", `${API_URL}${API_WORD}/${service}/${language}`, false ); // false for synchronous request
            xmlHttp.send( null );
            return JSON.parse(xmlHttp.response);
    }
    getAllTransLations(language, service) {
        let data = {
            [language]: this.getResponse(language,service)
        }
        //console.log(data);
        return data;
    }
}

export default new LocaleServcie();
