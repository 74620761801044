export default [
  {
    path: "/old/architekturerfassung",
    meta: {
      name: "Architekturerfassung View",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/old/architektur/ArchitekturErfassung.vue`)
  },
  {
    path: "/old/architekturNavigation",
    meta: {
      name: "ArchitekturNavigation View",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/old/architektur/ArchitekturNavigation.vue`),
    children: [
      {
        path: "/old/architekturUebersicht",
        meta: {
          name: "Uebersicht View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () => import(`@/views/old/architektur/Uebersicht.vue`)
      },
      {
        path: "/old/fachlicheAnforderungen",
        meta: {
          name: "Fachliche Anforderungen View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () =>
          import(
            `@/views/old/architektur/fachlicherKontext/AnforderungenFachlich.vue`
          )
      },
      {
        path: "/old/akteure",
        meta: {
          name: "Akteure View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () => import(`@/views/old/architektur/akteure/Akteure.vue`)
      },
      {
        path: "/old/schnittstellen",
        meta: {
          name: "Schnittstellen View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () =>
          import(`@/views/old/architektur/schnittstellen/Schnittstellen.vue`)
      },
      {
        path: "/old/technologieliste",
        meta: {
          name: "Technologieliste View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () =>
          import(`@/views/old/architektur/technologieliste/Technologieliste.vue`)
      },
      {
        path: "/old/bibliothekliste",
        meta: {
          name: "Bibliothekliste View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () =>
          import(`@/views/old/architektur/bibliothekliste/Bibliothekliste.vue`)
      },
      {
        path: "/old/finanzkennzahlen",
        meta: {
          name: "Finanzkennzahlen View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () =>
          import(`@/views/old/architektur/finanzkennzahlen/Ola.vue`)
      }
    ]
  },
  {
    path: "/old/berichteuebersicht",
    meta: {
      name: "Berichteübersicht View",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/old/berichte/BerichteUebersicht.vue`)
  },
  {
    path: "/old/main",
    meta: {
      name: "Main View Old",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/old/main/MainOld.vue`)
  },
];
