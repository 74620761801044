export default [
  {
    path: "/main",
    meta: {
      name: "Main View",
      isAuthenticated: true,
      roles: ['ais_user']
    },
    component: () => import(`@/views/main/Main.vue`)
  },
  {
    path: "/",
    meta: {
      name: "Main View",
      isAuthenticated: true,
      roles: ['ais_user']
    },
    component: () => import(`@/views/main/Main.vue`)
  },
  {
    path: "/entity/main",
    meta: {
      name: "Main View Entity",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/entity/Main.vue`)
  },
  {
    path: "/admin/main",
    meta: {
      name: "Main View Admin",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/admin/Main.vue`)
  },
  {
    path: "/admin/ident",
    meta: {
      name: "Main View Admin Ident",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/admin/ident/Main.vue`)
  },
  {
    path: "/admin/ident/ident",
    meta: {
      name: "Admin Ident",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/admin/ident/Ident.vue`)
  },
  {
    path: "/admin/ident/sequence",
    meta: {
      name: "Admin Ident-Sequence",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/admin/ident/Sequence.vue`)
  },
  {
    path: "/admin/text",
    meta: {
      name: "Main View Admin Text",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/admin/text/Main.vue`)
  },
  {
    path: "/admin/text/service",
    meta: {
      name: "Admin Text Servcie",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/admin/text/Service.vue`)
  },
  {
    path: "/admin/text/language",
    meta: {
      name: "Admin Text Language",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/admin/text/Language.vue`)
  },
  {
    path: "/admin/text/element",
    meta: {
      name: "Admin Text Element",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/admin/text/Element.vue`)
  },
  {
    path: "/denied",
    name: "denied",
    meta: {
      name: "Denied",
      isAuthenticated: false,
    },
    component: () => import(`@/views/Forbidden.vue`)
  },
  {
    path: "/*",
    meta: { isAuthenticated: false },
    component: () => import("@/views/NotFound.vue")
  },
  {
    path: "/:catchAll(.*)",
    meta: { isAuthenticated: false },
    component: () => import("@/views/NotFound.vue")
  }
];