export default [
{
path: "/entity/projekt",
meta: {
  name: "Projekt View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/Projekt.vue`)
},

{
path: "/entity/lieferant",
meta: {
  name: "Lieferant View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/Lieferant.vue`)
},

{
path: "/entity/nameOfSuperClass",
meta: {
  name: "NameOfSuperClass View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/NameOfSuperClass.vue`)
},

{
path: "/entity/dateTest",
meta: {
  name: "DateTest View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/DateTest.vue`)
},

//end here
];