// Lib imports
import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import store from "../store";

// Routes
import paths from "./paths";
import oldpaths from "./pathsOld";

Vue.use(Router);

// Create a new router
const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: paths.concat(oldpaths),

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

router.beforeEach((to, from, next) => {
    if (to.meta.isAuthenticated) {
      if (!store.getters.isLoggedIn) {
        next({ name: 'denied' })
      } else {
        if (to.meta.roles) {
          if (store.getters.getRoles.includes(to.meta.roles[0])) {
            next()
          }
          else {
            next({ name: 'denied' })
          }
        }
        else {
          next()
        }
      }
    } else {
      next()
    }
  }
);

Vue.use(Meta);

export default router;
