import Vue from 'vue'
import VueI18n from 'vue-i18n'
//import axios from "axios";
//import store from "./store"

//const API_URL = process.env.VUE_APP_ADMIN_LANGUAGE_URL;
//const API_WORD = "/core";

//axios.defaults.headers.common["Authorization"] = "Bearer " + store.getters.getToken;
import LocaleServcie from './locales/LocaleService.js'

Vue.use(VueI18n)

function loadLocaleMessages(language,service){
  const messages = LocaleServcie.getAllTransLations(language, service);
  //console.log(messages);
  return messages;
}

const myProfile = {
  locale: process.env.VUE_APP_I18N_LOCALE || 'dede',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'dede',
  service: process.env.VUE_APP_I18N_SERVICE
};


export default new VueI18n({
  locale: myProfile.locale,
  fallbackLocale: myProfile.fallbackLocale,
  service : myProfile.service,
  messages: loadLocaleMessages(myProfile.locale, myProfile.service),
  missing: (local,key) => {
    console.log('Tobie: ' + key + " l: " + local);
    LocaleServcie.sendMissing(local, key, myProfile.service);
  } 
})

/*Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'dede',
  fallbackLocale: 'dede',
  service: 'svc1',
  missing: (local,key) => {
    console.log('Tobie: ' + key + " l: " + local);
  }
})

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }
  return axios.get(`${API_URL}${API_WORD}/${i18n.service}/${lang}`).then(response => {
    let msgs = response.data
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, msgs.default)
    setI18nLanguage(lang)
  })
}

//export default i18n;*/
